
































































@import '@design';

.single-location--header {
  height: 400px;
  min-height: 50vh;
}
